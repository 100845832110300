import format from "date-fns/format";
import { string, nonEmptyString } from "check-types";

const options = [
  "yyyyMMddHHmmss",
  "MM/dd/yyyy pp",
  "Pp",
  "P",
  "Pp (z)",
  "MM/dd/yyyy",
  "yyyy-MM-dd"
];

const formatter = options.reduce((map, option) => {
  map.set(option, (timestamp: number) => format(timestamp, option));
  return map;
}, new Map());

export default (selector: string, date: string | number) => {
  if (!string(selector) || !nonEmptyString(selector.trim())) {
    throw new Error(
      "InvalidArgumentError: format param must be a non-empty string."
    );
  }

  const timestamp = new Date(date).getTime();

  if (new Date(timestamp).getTime() !== timestamp) {
    console.debug("dateformat failure", timestamp, date);
    throw new Error(
      "InvalidArgumentError: date param must be a valid JS date value."
    );
  }

  if (!formatter.has(selector.trim())) {
    throw new Error("Formatter not found");
  }

  const fn = formatter.get(selector.trim());

  return fn(timestamp);
};
