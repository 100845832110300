





















































































































import { Component, Vue } from "vue-property-decorator";
import axios, { AxiosResponse, AxiosError } from "axios";
import { getCSRFToken } from "../../services/actions";
import Emitter from "../../services/events/event-bus";
import { User } from "../../datatypes/User";
import isPlainObject from "lodash/isPlainObject";
import { nonEmptyString } from "../../util/string";
import startCase from "lodash/startCase";
enum Status {
  IDLE = "idle",
  SUBMITTING = "submitting",
  SUCCESS = "success"
}

@Component
export default class Navigation extends Vue {
  status: Status = Status.IDLE;
  loading: boolean = false;
  errors: string[] = [];
  csrfToken: string = "";
  urlpath: string = window.location.pathname;

  mounted() {
    this.getCSRFToken();
  }

  get authenticated() {
    return this.$store.getters["session/authenticated"];
  }

  get user(): User | null {
    const user = this.$store.getters["session/user"];
    if (user && isPlainObject(user)) {
      return user;
    }
    return null;
  }

  get environmentmode(): string | null {
    if (
      this.user &&
      isPlainObject(this.user) &&
      nonEmptyString(this.user.Mode)
    ) {
      return startCase(this.user.Mode.trim());
    }

    return null;
  }

  get isRainnAdmin() {
    if (this.authenticated && this.user) {
      const { Roles: roles = [] } = this.user;
      return roles.length && roles[0].ID === 1;
    }
    return false;
  }

  get isRainnAgent() {
    if (this.authenticated && this.user) {
      const { Roles: roles = [] } = this.user;
      return roles.length && roles[0].ID === 2;
    }
    return false;
  }

  get isUberAdmin() {
    if (this.authenticated && this.user) {
      const { Roles: roles = [] } = this.user;
      return roles.length && roles[0].ID === 3;
    }
    return false;
  }

  get isUberAgent() {
    if (this.authenticated && this.user) {
      const { Roles: roles = [] } = this.user;
      return roles.length && roles[0].ID === 4;
    }
    return false;
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  exit() {
    axios({
      url: `/api/v1/users/authentication`,
      method: "DELETE",
      headers: {
        "X-CSRF-Token": this.csrfToken
      }
    })
      .then((response: AxiosResponse) => {
        this.status = Status.SUCCESS;
        this.loading = false;
        this.$store.dispatch("session/reset");
        window.location.assign("/login");
      })
      .catch((err: AxiosError) => {
        this.status = Status.IDLE;
        this.loading = false;
        this.errors = [err.message];
        console.debug("[logout request] Error", err);
      });
  }
}
